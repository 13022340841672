// fonts
$first-font-family: "Inter-Regular";

$first-bold-font-family: "Inter-SemiBold";
$second-bold-font-family: "Inter-Bold";
$third-bold-font-family: "Inter-ExtraBold";

$first-medium-font-family: "Inter-Medium";

$first-light-font-family: "Inter-Light";
$second-light-font-family: "Inter-ExtraLight";

$first-thin-font-family: "Inter-Thin";

$first-black-font-family: "Inter-Black";

@import "../layout";

$inputRadius: 6px;
$inputPadding: 10px 12px;
$inputHeight: 38px;

.av2-form-field {
  display: flex;
  flex-flow: wrap column;

  label {
    @extend .av2-text-bold-1;
    font-size: 12px;
    margin-bottom: $spacing-xxs;
  }

  &-filter {
    position: relative;

    i.placeholder-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      right: $spacing-xs;
    }
  }
}

.av2-form-label {
  @extend .av2-text-normal-1;
  font-size: 14px;
  margin: 0;
  margin-bottom: 2px;
  margin-left: $spacing-xxs;
}

.av2-text-input {
  @extend .av2-text-normal-1;
  padding: $inputPadding;
  border-radius: $inputRadius;
  height: $inputHeight;

  &-filter {
    width: 100%;
  }
}

textarea.av2-text-area {
  @extend .av2-text-normal-1;
  padding: $inputPadding;
  border-radius: $inputRadius;
  border-style: solid;
  border-width: 1px;

  &:focus {
    outline: none !important;
  }
}

a.av2-form-helper {
  @extend .av2-text-normal-2;
  cursor: pointer;
}

.av2-dropdown {
  @extend .av2-text-normal-1;
  padding: $inputPadding;
  border-radius: $inputRadius;
  width: 100%;
}

.av2-card-label {
  @extend .av2-text-normal-1;
  font-size: 14px;
  width: 148px;
  height: auto;
  margin: 0;
}

.av2-card-value {
  font-size: 14px;
  min-width: 148px;
  height: auto;
  margin: 0 12px 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.av2-input-text-right {
  position: relative;
  display: inline-block;
  height: $inputHeight;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }

  .av2-input-text-right-label {
      @extend .av2-text-normal-1;
      position: absolute;
      top: 50%;
      margin-top: -0.5rem;
      right: 0.75rem;
  }
}

av2-calendar {
  .p-calendar {
    width: 100%;
  }
}

input[type="checkbox"] {
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  border: 1px solid;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  cursor: pointer;

  &:before {
      font-size: 8px;
      font-family: 'primeicons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-feature-settings: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      content: "\e909";
  }
}

$spacing-xxs: 6px;
$spacing-xs: 12px;
$spacing-s: 18px;
$spacing-m: 24px;
$spacing-l: 36px;
$spacing-xl: 48px;
$spacing-xxl: 72px;
$mobileResolution: 480px;
$tabletResolution: 1024px;
$maxDesktopResolution: 1560px;
$max-content-width: 1356px;

.av2-container {
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  grid-column-gap: $spacing-m;
}

.del-after-detach {
}

.p-accordion .p-accordion-content {
  padding: 24px;
}

.multiLevelAccordionTab > .p-accordion-tab > .p-toggleable-content > .p-accordion-content {
  padding: 0 !important;
}

@media only screen and (max-width: $mobileResolution) {
  .av2-container {
    grid-template-columns: repeat(4, [col-start] 1fr);
  }
}

@media only screen and (min-width: $mobileResolution) and (max-width: $tabletResolution) {
  .av2-container {
    grid-template-columns: repeat(8, [col-start] 1fr);
    grid-column-gap: $spacing-s;
  }
}

@import "../../layout";
@import "colors";

$dropdownPadding: 0.5rem 1rem;

.main-container {
  background: $backgroundMain;

  .mobile-navi {
    border: solid 1px $dark-200;
  }

  .side-menu-button {
    border: solid 1px $dark-200;
  }
}

.header-actions {
  i {
    color: $dark-600;
  }
}

.side-menu-container {
  background: $dark-100;

  .menu-item {
    .menu-item-label,
    i {
      color: $dark-900;
    }

    .indicator {
      color: $dark-900;
    }

    &.active {
      ::after {
        background-color: $primary-500;
      }

      .menu-item-label,
      i {
        color: $primary-500;
      }
    }

    &:hover {
      .menu-item-label,
      i {
        color: $primary-500;
      }
    }
  }

  .submenu-item {
    color: $dark-900;

    &.active {
      .submenu-item-label {
        font-weight: 600 !important;
      }
    }

    &:hover {
      .submenu-item-label {
        font-weight: 600 !important;
      }
    }
  }
}

.av2-form-field {
  label {
    color: $dark-700;
  }

  &-filter {
    i {
      color: $dark-600;
    }
  }
}

textarea.av2-text-area {
  color: $dark-900;
  border-color: $dark-300;

  &:focus {
    border-color: $dark-900;
  }

  &:disabled {
    border-color: $dark-300;
    background: $dark-100;
  }

  &.ng-dirty.ng-invalid {
    border-color: $danger-500;
  }
}

.av2-link-button {
  color: $primary-500;

  &:disabled {
    color: $dark-700;
  }
}

.av2-badge {
  color: #ffffff;

  &.primary {
    background: $primary-500;
  }

  &.disabled {
    background: $dark-700;
  }

  &.secondary {
    background: $primary-800;
  }

  &.danger {
    background: $danger-500;
  }

  &.success {
    background: $success-500;
  }

  &.warning {
    background: $warning-500;
  }

  &.alarm-state {
    &.unassigned {
      background: $warning-500;
    }

    &.processing {
      background: $primary-500;
    }

    &.closed {
      background: $success-500;
    }
  }

  &.task-state {
    &.new {
      background: $primary-500;
    }

    &.cancelled {
      background: $danger-500;
    }

    &.handed-over {
      background: $warning-500;
    }

    &.rejected {
      background: $danger-500;
    }

    &.accepted {
      background: $warning-500;
    }

    &.failed {
      background: $danger-500;
    }

    &.completed {
      background: $success-500;
    }

    &.cant-complete {
      background: $danger-500;
    }
  }

  &.patrol-state {
    &.pending {
      background: $dark-400;
      color: $dark-700;
    }

    &.in-progress {
      background: $primary-200;
      color: $primary-600;
    }

    &.finished {
      background: $success-500;
      color: white;
    }
  }

  &.user-status {
    &.inactive {
      background: $danger-500;
      color: white;
    }

    &.active {
      background: $success-500;
      color: white;
    }
  }
}

.av2-card-list {
  &-icon {
    color: $primary-500;
  }
}

.av2-table-selected-filters-container {
  color: $dark-700;
}

.av2-filter-badge {
  color: $primary-600;
  background-color: $primary-200;

  i {
    color: #fff;
    background-color: $primary-500;
  }

  &.av2-filter-badge-add {
    background-color: $dark-200;
    color: $primary-200;
    i.fa.fa-check {
      background-color: $success-500;
    }
  }
  &.next-day {
    background-color: $primary-600;
    .timetable-badge-time {
      color: white;
    }
    i {
      color: #fff;
      background-color: $primary-400;
    }
  }
}

av2-lazy-selected-chips {
  .av2-filter-badge {
    color: $primary-600;
    background-color: $primary-200;

    i {
      color: #fff;
      background-color: $primary-500;
    }

    &.av2-filter-badge-add {
      background-color: $dark-200;
      color: $primary-200;
      i.fa.fa-check {
        background-color: $success-500;
      }
    }
    &.next-day {
      background-color: $primary-600;
      .timetable-badge-time {
        color: white;
      }
      i {
        color: #fff;
        background-color: $primary-400;
      }
    }
  }
}

.av2-stepper-container {
  border-top-color: $dark-300;
}

.av2-stepper-title {
  color: $dark-900;
}

.av2-stepper-subtitle {
  color: $dark-900;
}

.av2-form-label {
  color: $dark-700;
}

.av2-form-helper {
  &.link {
    color: $primary-500;
  }
}

.contacts-container {
  border-bottom-color: $dark-300;
}

.av2-route-control-points-card {
  border-color: $dark-300;

  &-body {
    background: $dark-100;
    border-top: $dark-300 solid 1px;
  }
}

.av2-forms-selector-icon-helper-card {
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  border-color: $dark-300;

  .av2-forms-selector-icon-helper-card-icon-container {
    background-color: $dark-100;
    color: $primary-500;
  }

  .av2-forms-selector-icon-helper-card-title {
    color: $dark-900;
  }

  .av2-forms-selector-icon-helper-card-subtitle {
    color: $dark-700;
  }

  .av2-forms-selector-icon-helper-card-selected-icon {
    color: $primary-500;
  }

  &.pressed,
  &.selected {
    border-color: $primary-500;
  }
}

.av2-dropdown-with-badge-item {
  .av2-dropdown-with-badge-item-icon {
    color: $primary-500;
  }
}

.av2-route-form {
  .control-points-container {
    border-bottom-color: $dark-300;
  }
}

.av2-tag-control-point-item {
  border-color: $dark-300;

  .av2-tag-control-point-item-icon {
    color: $primary-500;
  }

  .av2-tag-control-point-item-delete-icon {
    color: $dark-700;
  }
}

.av2-map-control-point-list-row {
  i {
    color: $primary-500;
  }

  .av2-map-control-point-list-item {
    border-color: $dark-300;
  }
}

.av2-alarm-card {
  background-color: $primary-100;
}

.tag-button-section {
  .tag-button-delete {
    color: $danger-500;
  }
}

.pricelist-card {
  &.selected {
    background-color: #e3f2fd;
  }
  &.disabled {
    background-color: $dark-100;
  }
}

.menu-items-container {
  color: $backgroundMain;
}

.main-container {
  .mobile-navi {
    background-color: $backgroundMain;
  }

  .side-menu-button {
    background-color: $backgroundMain;

    &-close {
      background-color: $primary-500;

      i {
        color: $backgroundMain;
      }
    }
  }
}

::ng-deep .base-role-table p-checkbox .p-disabled {
  background-color: $dark-200 !important;
  border-color: $dark-100 !important;
}

.info-bar {
  color: $infoBar;
}

.p-invalid {
  color: $danger-500;
}

.av2-filter-option {
  color: #495057;
}

.av2-filters-sidebar-header {
  color: $dark-900;
}

.av2-info-dialog-container .av2-info-dialog-message {
  color: $dark-900;
}

.av2-info-dialog-container .av2-info-dialog-refresh-session-counter {
  color: $danger-500;
  font-weight: bold;
}

.dropdown-selector-container
  .dropdown-selector-overlay
  .dropdown-selector-items-wrapper
  .dropdown-selector-items
  .dropdown-selector-item {
  background-color: $backgroundMain;

  &:hover {
    background: $primary-100;
  }

  &.not-clickable {
    &:hover {
      background: $backgroundMain;
    }
  }
}

@media only screen and (max-width: $tabletResolution) {
  .side-menu-container {
    background: $backgroundMain;
  }
  .alarm-type-form {
    border-bottom: 1px solid $dark-300;
  }
}

@media only screen and (max-width: $mobileResolution) {
  .av2-card-list {
    background-color: $cardBackgroundColor;
  }

  .av2-card-list-text-body {
    color: $dark-700;
  }
  .av2-card-list-icon {
    background-color: $dark-100;
  }
  .p-tabview-nav-prev,
  .p-tabview-nav-next {
    visibility: visible !important;
    color: #000;
    z-index: 1 !important;

    &.p-link:focus {
      box-shadow: none;
    }
  }
  .card-timetable-selected-days {
    border: 1px solid $dark-300 !important;
  }
  .card-timetable-type {
    border: 1px solid $dark-300 !important;
  }
}

.av2-pricelist-product-icon {
  i {
    color: $dark-600;
  }
}

.av2-product-details {
  .av2-text-normal-2 {
    color: $dark-700;
  }
}

.av2-product-button-delete {
  background-color: #ffff !important;
  color: $dark-600 !important;
  border-color: $dark-300 !important;

  &:hover {
    background-color: $dark-200 !important;
    color: $dark-700 !important;
  }
}

.av2-client-logo-button {
  background-color: #ffff !important;
  color: $dark-600 !important;
  border-color: $dark-300 !important;

  &:hover {
    background-color: $dark-200 !important;
    color: $dark-700 !important;
  }
}

.av2-pricelist-table {
  .av2-table-link-column {
    .av2-table-link-column-details {
      color: $dark-600;
    }
  }
}

.av2-link-button-danger {
  color: $danger-500 !important;

  &:disabled {
    color: $dark-600 !important;
  }
}

.av2-divider {
  border-bottom-color: $dark-300;
}

.av2-icon-only-button {
  background-color: $dark-200 !important;
  color: $primary-500 !important;

  &:hover {
    background-color: $dark-300 !important;
    color: $primary-600 !important;

    &:disabled {
      background-color: $dark-200 !important;
      color: $dark-600 !important;
    }
  }

  &:disabled {
    color: $dark-600 !important;
  }
}

.av2-info-bar {
  background-color: $primary-100;
  color: #0f1725;
}

.signal-color-offline {
  color: $dark-600;
}

.signal-color-online {
  color: $success-500;
}

.status-color-empty {
  color: $danger-600;
}

.status-color-half {
  color: $warning-600;
}

.status-color-full {
  color: $success-600;
}

.av2-section {
  border-bottom: $sectionSepator;
}

.main-container-shadow {
  &::before {
    background: $dark-700;
  }
}

.av2-stepper-progress-bar {
  &.big,
  &.success {
    .p-progressbar .p-progressbar-value {
      background: $success-500;
    }
  }

  &.medium,
  &.warning {
    .p-progressbar .p-progressbar-value {
      background: $warning-500;
    }
  }

  &.small,
  &.danger {
    .p-progressbar .p-progressbar-value {
      background: $danger-500;
    }
  }

  &.primary {
    .p-progressbar .p-progressbar-value {
      background: $primary-500;
    }
  }

  &.smallProgressBar {
    .p-progressbar-determinate,
    .p-progressbar-indeterminate {
      width: 50%;
      height: 4px;
    }
  }
}

.home {
  color: $primary-500;
}

.av2-list-empty-state-container {
  border-top: 2px solid $dark-300;
  border-bottom: 2px solid $dark-300;
}

.av2-radio-button-horizontal {
  .p-radiobutton-checked ~ .p-radiobutton-label {
    border-color: $primary-500;

    &:hover {
      background-color: #ffff;
    }
  }

  .p-radiobutton-label {
    border: $buttonBorder;
    background-color: #ffff;

    &:hover {
      background-color: $dark-100;
    }
  }
}

.av2-icon-outline {
  color: $primary-500;
}

h3 {
  border-bottom: 2px solid $dark-300;
}

.av2-card-details {
  border: $cardBorder;
  background-color: $cardBackgroundColor;
}

.av2-card-details-pdf {
  border: $cardBorder;
  background-color: $cardBackgroundColor;
}

.av2-card-list {
  border: $cardBorder;
}

.av2-table-selection-buttons-container {
  color: $dark-700;
}

.av2-table-link-column {
  color: $primary-500;
}

.av2-table-bold-link-column {
  color: $primary-500;
}

.currency-section {
  .av2-form-label {
    &.currency-section-item {
      .currency-section-details {
        color: $dark-600;
      }
    }
  }
}

.currency-button-section {
  .currency-button-delete {
    color: $danger-500;
  }
}

.card-timetable-type {
  &.active {
    border: 1px solid $primary-600 !important;
  }

  .card-timetable-type-icon {
    background-color: $dark-300;

    i {
      color: $primary-600;
    }
  }

  .card-timetable-type-check {
    i {
      color: $primary-600;
    }
  }
}

.p-inputgroup-addon {
  background-color: $backgroundMain;
}

.logo-container {
  background-image: url("../../../assets/images/av2-logo-color.png");
}

.form-box {
  .logo {
    background-image: url("../../../assets/images/av2-logo-color.png");
  }
}

.av2-card-list-text-body {
  color: $dark-700;
}

.av2-card-list-text-body-small {
  color: $dark-700;
}

.av2-card-details-text-body {
  color: $dark-700;
}

.av2-card-details-text-body-small {
  color: $dark-700;
}

.containter {
  .success-body {
    color: $dark-900;
  }
}

.form-box {
  .sign-in-card {
    .form-container {
      .sing-in-input {
        label {
          color: $dark-700;
        }
      }
    }
  }
}

.form-container {
  .sing-in-input {
    label {
      color: $dark-700;
    }
  }

  .change-password-input {
    label {
      color: $dark-700;
    }
  }

  .form-body {
    color: $dark-900;
  }

  .mail-password-input {
    label {
      color: $dark-700;
    }
  }
}

.av2-card-value {
  color: $dark-900;
}

.av2-input-text-right {
  .av2-input-text-right-label {
    color: $dark-700;
  }
}

.av2-employee-rating,
.dashboard-more-info-card {
  background-color: $dark-100;
}

.rating-section-button > .p-button.p-button-text {
  background-color: $dark-100;
}

.rating-section-button:hover .p-button.p-button-text {
  background-color: $dark-200;
}

.av2-simple-chart-card {
  border-color: $dark-300;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

  .av2-simple-chart-title {
    color: $dark-700;
  }

  .av2-simple-chart-value {
    color: $dark-900;
  }

  .av2-simple-chart-subvalue {
    color: $dark-700;
  }
}

.av2-chart-card {
  border-color: $dark-300;
  .av2-chart-card-title,
  .av2-chart-count {
    color: $dark-900;
  }

  .av2-chart-title,
  .av2-chart-amount {
    color: $dark-700;
  }
}

.av2-work-time-report-table {
  .p-datatable .p-datatable-tbody > tr.not-working-day {
    background-color: $dark-100;
  }
}

.av2-overlay-panel-item-icon {
  color: $primary-500;
}

app-paginator {
  .av2-paginator-container {
    .av2-text-normal-2 {
      color: $dark-700;
    }

    .p-paginator {
      .p-paginator-pages .p-paginator-page {
        color: $primary-500;

        &.p-highlight {
          background: $primary-500;
          color: #fff;
        }
      }
    }
  }
}

.av2-employee-timetable-form-container {
  border-top: solid 1px $dark-300;
}

.av2-employee-timetable-form-labels-container {
  color: $dark-600;
}

.av2-employee-timetable-form-day-container {
  border-top: solid 1px $dark-300;
}

.av2-group-button {
  color: $dark-600;

  &:hover {
    i {
      color: $primary-500;
    }
  }
}

.av2-sign-in-toast {
  color: $dark-900;
}

.site-card, .device-card {
  box-shadow: 3px 3px 1px $dark-100;

  .icon-container {
    .icon {
      color: $primary-500;
    }
  }
}

.type-card {
  box-shadow: 3px 3px 1px $dark-100;

  &.selected {
    border: 2px solid $primary-500;
  }

  &:hover {
    box-shadow: 3px 3px 1px $dark-200;
  }

  .p-button.p-button-text {
    background-color: $dark-100;
  }

  .status-icon-container {
    color: $primary-500;
  }
}

.type-card:hover .p-button.p-button-text {
  background-color: $dark-200;
}

.invalid-sign-in-info-bar {
  background: $danger-100;
  color: $danger-700;
}

.av2-action-point-task {
  i {
    color: $primary-500;
  }
}

.av2-selected-filter-label {
  @extend .av2-text-normal-1;
}

.p-datatable .p-datatable-loading-overlay {
  background-color: #fff;
}

.av2-tag-control-point-item {
  background-color: #fff;
}

.task-avatar {
  color: $primary-500;
}

.av2-notification-panel-item {
  &:hover {
    background: $primary-100;
  }

  &.unread {
    background: $dark-100;

    &:hover {
      background: $primary-100;
    }
  }

  border-bottom: 1px solid $dark-300;

  .unread-icon {
    border-radius: 50%;
    background-color: $primary-500;
    color: white;
    align-items: center;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    padding: 0px;
  }

  .read-icon {
    color: $dark-700;
  }
}

.av2-notification-search-container {
  border-bottom: 1px solid $dark-300;
}

.av2-notification-title {
  color: $dark-900;
}

.av2-notification-description {
  color: $dark-900;
}

.av2-notification-date {
  color: $dark-700;
}

::-webkit-scrollbar-track {
  border-radius: 20px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  border-radius: 20px;
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: $primary-500;
  border: 1px solid #f5f5f5;
}

@media only screen and (max-width: $tabletResolution) {
  .av2-notification-toggle-container {
    color: $primary-500;
  }
}

.alarm-table {
  & .tr-group {
    border-bottom: 1px solid $dark-300;
  }

  & .td-group {
    background: transparent !important;
    border-bottom: unset !important;
    padding: 0 12px !important;
  }

  & .border-r-separator {
    border-right: 2px solid $dark-300 !important;
  }

  & .border-l-separator {
    border-left: 2px solid $dark-300 !important;
  }
}

.patrol-table,
.av2-alarm-type-table,
.av2-alarm-type-template-table {
  & .border-r-separator {
    border-right: 2px solid $dark-300 !important;
    box-shadow: none;
  }

  & .border-l-separator {
    border-left: 2px solid $dark-300 !important;
    box-shadow: none;
  }
}

.av2-notification-header,
.close-icon {
  color: $dark-900;
}

.avatar-container {
  display: flex;
  align-items: center;
  color: #000;

  .avatar {
    margin-right: 10px;
  }
}

.av2-notification-header-sticky-group {
  background-color: $backgroundMain;
}

.card-product-with-discount {
  .product-price {
    grid-column: 7/10;
    text-decoration: line-through 2px $dark-900;
  }
}

.overlayButton {
  border-color: $dark-300 !important;
  color: $dark-900 !important;
}

.patrols-info-points {
  background-color: $dark-100;
}

.pricelist-form-image {
  background-image: url("../../../assets/images/placeholders-product.png");
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}

.logo-form-image {
  background-image: url("../../../assets/images/placeholders-product.png");
  background-size: cover;
  background-position-y: center;
  background-position-x: center;
}

.p-tabview-nav-prev,
.p-tabview-nav-next {
  visibility: hidden;
}

.alarm-list-accordion {
  border: solid 1px $dark-200;
  border-radius: 6px;
  overflow: hidden;
  margin-top: $spacing-m;
}

.alarm-list-accordion-no-margin {
  border: solid 1px $dark-200;
  border-radius: 6px;
  overflow: hidden;
}

.last-chart-hour {
  color: #000;
}

.last-chart-line {
  border-right: 1px solid #eaeaea;
}

.av2-card-list-icon-small {
  background-color: $dark-100;
}

.navi-clock {
  color: $dark-600;
}

.graphical-report-spinner {
  background: $backgroundMain;
}

.edit-device-form-spinner {
  background: $backgroundMain;
}

.selected-product-info {
  color: $dark-600;
}

.device-info {
  color: $dark-600;
}

.icon-lease-ended {
  color: $danger-500;
}

.icon-lease {
  color: #c006c9;
}

.av2-select-dropdown-item-disable {
  color: $dark-600 !important;
  i {
    color: $dark-600;
  }
}

.av2-link-button-days {
  color: #000 !important;
  border: 1px solid $dark-300 !important;
  background-color: #fff !important;

  &.active {
    @extend .av2-text-bold-1;
    border: 2px solid $primary-500 !important;
  }
}

.range-picker-left-container {
  background-color: $dark-100 !important;
}

.p-datepicker-special-wrapper {
  padding: 0 !important;
}

.p-datepicker-month,
.p-datepicker-year {
  @extend .av2-text-bold-1;
  font-weight: bold;
  color: #000 !important;
}

.range-picker-hour {
  border-top: 1px solid $dark-300;
}

.p-calendar-timeonly {
  border: none !important;
}

.av2-range-picker-button:enabled:hover {
  background: #1458b0 !important;
  color: #ffffff !important;
}

.p-multiselect-clear-icon {
  color: #6c757d;
}

av2-lazy-list {
  .p-multiselect-item {
    color: #495057 !important;
  }
  .p-highlight:not(.p-checkbox-box) {
    color: #495057 !important;
  }
  .p-multiselect-empty-message {
    color: #000 !important;
  }
}

.assignment-icon {
  color: $primary-500;
}

@import "../fonts";
@import "../layout";

$buttonRadius: 4px;
$buttonPadding: 10px 16px;
$buttonHeight: 38px;

.av2-button {
  @extend .av2-text-normal-1;
  font-weight: 600;
  height: $buttonHeight;
  padding: $buttonPadding;
  &:not(.av2-close-button) {
    border-radius: 6px;
  }
  &-right {
    margin-right: $spacing-xs !important;
  }
}

.av2-link-button {
  @extend .av2-text-normal-1;
  padding: 0;
  font-weight: 600 !important;
  font-size: 12px !important;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  i {
    &.left {
      margin-right: $spacing-xxs;
    }

    &.right {
      margin-left: $spacing-xxs;
    }
  }

  &:hover {
    text-decoration: underline;
  }

  &:disabled {
    text-decoration: none;
  }
}

.av2-badge {
  @extend .av2-text-normal-1;
  padding: 2px $spacing-xxs;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: fit-content;
  user-select: none;
  white-space: nowrap;
  height: 22px;
}

.av2-filter-badge {
  @extend .av2-text-bold-1;
  letter-spacing: -0.26px;
  font-size: 12px;
  padding: 3px 3px 3px 12px;
  border-radius: 11px;
  height: 25px;
  display: flex;
  width: max-content;
  min-width: max-content;
  overflow: hidden;
  column-gap: $spacing-xxs;
  span {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  i {
    font-size: 10px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.av2-close-button {
  border-radius: 50%;
  padding: 0;
}

.av2-rectangle-button {
  margin-right: $spacing-xs !important;
}

.av2-icon-outline {
  font-weight: 400;
  width: 12px;
  height: 14px;
  margin-left: $spacing-xxs;
  margin-right: $spacing-xxs;
  cursor: pointer;
}

button[type="button"] {
  &:disabled {
    cursor: not-allowed;
  }
}

.av2-overlay-panel-button {
  i {
    height: 100%;
    margin-left: $spacing-xs;
    display: flex;
    align-self: flex-end;
    font-size: 22px;
  }
}

.av2-overlay-panel-item-icon {
  font-weight: 400;
  width: 12px;
  height: 14px;
  margin-right: $spacing-xs;
  cursor: pointer;
}

.av2-group-button {
  cursor: pointer;
  height: 28px;
  width: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px;
  border-left: none;
  &:first-child {
    border-left: 1px solid;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}


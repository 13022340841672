// Main colors
$backgroundMain: #ffffff;
$infoBar:#0f1725;
$sectionSepator: 1px solid #dce0e5;

$dark-900: #000;
$dark-800: #3d3f40;
$dark-700: #525457;
$dark-600: #8f9398;
$dark-500: #b8bec3;
$dark-400: #ced4da;
$dark-300: #dce0e5;
$dark-200: #e6e9ec;
$dark-100: #f0f2f3;
$success-900: #0f2212;
$success-800: #1e4424;
$success-700: #2d6636;
$success-600: #3c8848;
$success-500: #4bab5a;
$success-400: #6fbb7b;
$success-300: #93cc9c;
$success-200: #b7ddbd;
$success-100: #dbeede;
$danger-900: #2d0401;
$danger-800: #5a0803;
$danger-700: #880c05;
$danger-600: #b51007;
$danger-500: #e31409;
$danger-400: #e8433a;
$danger-300: #ee726b;
$danger-200: #f3a19c;
$danger-100: #f9d0cd;
$amber-400: #fb8333;
$primary-900: #05162c;
$primary-800: #0a2c58;
$primary-700: #0f4284;
$primary-600: #1458b0;
$primary-500: #1a6fdd;
$primary-400: #478be3;
$primary-300: #75a8ea;
$primary-200: #a3c5f1;
$primary-100: #d1e2f8;
$warning-900: #644c11;
$warning-800: #967319;
$warning-700: #c89922;
$warning-600: #e1ac26;
$warning-500: #fbc02b;
$warning-400: #fbcc55;
$warning-300: #fcd97f;
$warning-200: #fde5aa;
$warning-100: #fef2d4;

$sectionSepator: 1px solid $dark-300;
$buttonBorder: solid 1px $dark-300;
$cardBorder: solid 1px $dark-300;
$cardBackgroundColor: #fff;
